import createBrowserHistory from "history/createBrowserHistory";
import ReactGA from "react-ga";

let GAListener = () => {};

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_NUMBER) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_NUMBER, {
    debug: process.env.NODE_ENV === "development",
  });
  ReactGA.pageview(window.location.pathname, [], "POKERithm");

  GAListener = (location, action) => {
    ReactGA.pageview(location.pathname, [], "POKERithm");
  };
}

const browserHistory = createBrowserHistory();
browserHistory.listen(GAListener);

export default browserHistory;
