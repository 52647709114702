import React from "react";
import { mapProps } from "recompose";
import * as R from "ramda";
import history from "browserHistory";

const Button = ({
  id,
  x,
  y,
  backgroundColor,
  backgroundHoverColor,
  children,
  type,
  onClick,
  visible,
  value,
  width,
  height,
  style,
  cursor,
  fontSize,
  opacityMatrix,
  icon: Icon,
  iconStyles,
  w = 20,
  h = 20,
  ...props
}) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
    width={w}
    height={h}
  >
    <defs>
      <rect id={`${id}-button-a`} width={width} height={height} rx={2} />
      <radialGradient
        cx="49.014%"
        cy="16.673%"
        fx="49.014%"
        fy="16.673%"
        r="68.487%"
        gradientTransform="matrix(0 1 -.84095 0 .63 -.323)"
        id={`${id}-button-c`}
      >
        <stop stopColor="#909090" offset="0%" />
        <stop offset="100%" />
      </radialGradient>
    </defs>
    <g
      onClick={onClick}
      style={{ cursor }}
      fill="none"
      fillRule="evenodd"
      id={id}
    >
      <g
        opacity={opacityMatrix[0]}
        style={{ width: `${width}px` }}
        transform="translate(1 1)"
      >
        <mask id={`${id}-button-b`} fill="#fff">
          <use xlinkHref={`#${id}-button-a`} />
        </mask>
        <g mask={`url(#${id}-button-b)`} fill={backgroundColor}>
          <set
            attributeName="fill"
            to={backgroundHoverColor}
            begin={`${id}.mouseover`}
            end={`${id}.mouseout`}
          />
          <path d={`M-2-1h${width + 6}v${height + 1}H-2z`} />
        </g>
      </g>
      <rect
        fill={`url(#${id}-button-c)`}
        opacity={opacityMatrix[1]}
        style={{ mixBlendMode: "overlay" }}
        x={1}
        y={1}
        width={width}
        height={height}
        rx={2}
      />
      <Icon
        width={iconStyles.size}
        height={iconStyles.size}
        x={iconStyles.size / 2}
        y={(height - iconStyles.size) / 2}
      />
      <text
        opacity={opacityMatrix[2]}
        fontFamily="Houschka Pro"
        fontSize={fontSize}
        fill="#FFF"
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan
          x={width / 2 + iconStyles.paddingRight}
          y={(height + fontSize) / 2}
        >
          {children}
        </tspan>
      </text>
      <path
        d={`M5 13.121l.069-7.000C3.485 6.0 6.35 6.013 6.663 5h${width -
          12}.805c1.373.457 1.823 1.239 1.35 2.346-.709 1.66-${width -
          40}.39-4.007-${width - 11}.646 ${Math.floor(
          height / 2
        )}.226-.901.636-2.093.84-2.103-1.392A881.406 881.406 0 0 0 5 13.121z`}
        fill="#FFF"
        style={{ mixBlendMode: "soft-light" }}
        opacity={opacityMatrix[3]}
      />
      <path
        d={`M7 4h${width - 12}a3 3 0 0 1 3 3v${height -
          12}a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v${height -
          12}a2 2 0 0 0 2 2h${width - 12}a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7z`}
        fill="#CFCFCF"
        opacity={opacityMatrix[4]}
        style={{ mixBlendMode: "color-burn" }}
      />
    </g>
  </svg>
);

Button.defaultProps = {
  x: 0,
  y: 0,
  text: "",
  type: "fold",
  disabled: false,
  visible: true,
  style: {},
  width: 118,
  height: 34,
  fontSize: 15,
  id: "button",
  icon: () => null,
  iconStyles: { size: 0, paddingRight: 0 },
};

const typesMap = {
  fold: {
    active: {
      backgroundColor: "#E85757",
      backgroundHoverColor: "#eb7070",
    },
    disabled: {
      backgroundColor: "#E85757",
      backgroundHoverColor: "#E85757",
    },
  },
  raise: {
    active: {
      backgroundColor: "#5ACCD6",
      backgroundHoverColor: "#72d3dc",
    },
    disabled: {
      backgroundColor: "#5ACCD6",
      backgroundHoverColor: "#5ACCD6",
    },
  },
  call: {
    active: {
      backgroundColor: "#5ACCD6",
      backgroundHoverColor: "#72d3dc",
    },
    disabled: {
      backgroundColor: "#5ACCD6",
      backgroundHoverColor: "#5ACCD6",
    },
  },
  check: {
    active: {
      backgroundColor: "#5ACCD6",
      backgroundHoverColor: "#72d3dc",
    },
    disabled: {
      backgroundColor: "#5ACCD6",
      backgroundHoverColor: "#5ACCD6",
    },
  },
  facebook: {
    active: {
      backgroundColor: "#B1B2DC",
      backgroundHoverColor: "#B9BAE4",
    },
    disabled: {
      opacityMatrix: [0.765, 0.569, 0.536, 0.126, 0.317],
      backgroundColor: "#B1B2DC",
      backgroundHoverColor: "#B1B2DC",
    },
  },
  primary: {
    active: {
      backgroundColor: "#E6B7D2",
      backgroundHoverColor: "#e9bcd6",
    },
    disabled: {
      opacityMatrix: [0.765, 0.569, 0.536, 0.126, 0.317],
      backgroundColor: "#E6B7D2",
      backgroundHoverColor: "#E6B7D2",
    },
  },
  basic: {
    active: {
      backgroundColor: "#CFCFCF",
      backgroundHoverColor: "#d3d3d3",
    },
    disabled: {
      backgroundColor: "#a3a3a3",
      backgroundHoverColor: "#a3a3a3",
    },
  },
};

const opacityMatrix = {
  disabled: [0.565, 0.066, 0.436, 0.126, 0.117],
  active: [1.0, 0.751, 1.0, 0.222, 0.5],
};

export default mapProps(
  ({ type, id, to, disabled, loading, onClick, ...props }) => {
    const matrixKey = disabled || loading ? "disabled" : "active";
    const cursor = loading ? "progress" : disabled ? "not-allowed" : "pointer";

    return {
      cursor,
      id: `${id}_${matrixKey}`,
      onClick:
        disabled || loading ? () => {} : to ? () => history.push(to) : onClick,
      opacityMatrix: opacityMatrix[matrixKey],
      ...typesMap[type][matrixKey],
      ...props,
    };
  }
)(Button);
