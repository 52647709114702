import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { isNilOrEmpty, isPresent } from "utils/common";
import Text from "components/Text";
import { SizeSensor } from "libreact/lib/SizeSensor";
import { Center, HandLog } from "scenes/Games/Show/PostMatchStatistic/ui";
import PerfectScrollbar from "react-perfect-scrollbar";
import Spin from "components/Spin";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import styled from "styled-components";
import { Table } from "antd";
import LogList from "../../../LogsAlert/LogList";
import LogsAlert from "../../../LogsAlert";
import { renderAggression, renderValueQuotient } from "./utils";
import SmallCard from "scenes/Games/Show/Card/Mini/EndCommunity";
import { columns as oldColumns } from "./utils";

const tableWidth = "100%";

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  thead tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  thead tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  tfoot tr:first-child th:first-child {
    border-bottom-left-radius: 10px;
  }

  tfoot tr:first-child th:last-child {
    border-bottom-right-radius: 10px;
  }

  thead {
    border-radius: 16px;
    top: -8px;
    z-index: 2;
    position: sticky;
    background-color: white;
  }

  thead tr {
    background: #1e2e37;
    color: #fff;
    padding: 5px;
    border-radius: 16px;

    th {
      padding: 5px;
      @media all and (min-width: 768px) {
        padding: 10px;
      }

      font-weight: semibold;
      text-align: center;
      border-right: 2px solid #fff;
    }

    th:last-child {
      border-right: none;
    }
  }

  tfoot {
    th {
      padding: 5px;
      padding-top: 20px;
      padding-bottom: 20px;
      @media all and(min-width: 768px) {
        padding: 20px;
      }
    }

    z-index: 2;
    position: sticky;
    bottom: -8px;
    background-color: white;
  }

  tbody {
    background: #f6f7f7;
    width: 100%;

    tr:hover {
      background: #e8e8e8;
      cursor: pointer;
    }

    td {
      @media all and (min-width: 768px) {
        padding: 10px;
        font-size: 18px;
      }

      width: min-content;

      font-size: 12px;

      padding: 5px;
      text-align: center;
      color: #566770;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    td:last-child {
      border-right: none;
    }
  }

  tfoot {
    background: #1e2e37;
    color: #fff;

    th {
      text-align: center;

      span.label {
        font-size: 14px;
        color: #fff;
        padding-bottom: 5px;
        display: block;
      }

      span.value {
        display: block;
        font-size: 18px;
      }
    }
  }
`;

const getSuit = (s) => {
  if (s === "c") return "clubs";
  if (s === "d") return "diamonds";
  if (s === "h") return "hearts";
  if (s === "s") return "spades";
};

export default ({ data, closeModal, logs }) => {
  const [reducedLogs, setReducedLogs] = useState([]);
  const [currentLog, setCurrentLog] = useState(null);
  const [showLog, setShowLog] = useState(false);

  useEffect(() => {
    const reducedLogs = logs.reduce(
      (acc, log) => {
        if (log.type === "divider") {
          acc.push([]); // Start a new sub-array
        } else {
          acc[acc.length - 1].push(log); // Add the log object to the current sub-array
        }
        return acc;
      },
      [[]]
    );

    setReducedLogs(reducedLogs);
  }, [logs]);

  const openLogs = (log) => {
    setCurrentLog(log);
    setShowLog(true);
  };

  const [columnsData, setColumnsData] = useState([]);
  useEffect(() => {
    setColumnsData(oldColumns(reducedLogs, openLogs));
  }, [reducedLogs]);

  function aggressionFooter() {
    const aggressionCount = data.hands_statistic.filter(
      (hand) => !hand.contested
    ).length;

    const aggression = (
      data.match_results.aggression.total / aggressionCount
    ).toFixed(2);

    return (
      <>
        <span
          style={{
            color: aggression < 0 ? "#E06666" : "#5DC05E",
            display: "flex",
            fontSize: "14px",
            marginTop: "8px",
            fontWeight: "bold",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {aggression}{" "}
          <span
            className="text-gray-300 font-normal"
            style={{ fontSize: "10px" }}
          >
            ({aggressionCount})
          </span>
        </span>
      </>
    );
  }

  function pointsFooter() {
    const pointsCount = data.hands_statistic.length;
    const points = data.match_results.msr.points.value;

    return (
      <>
        <span
          style={{
            color: points < 0 ? "#E06666" : "#5DC05E",
            display: "flex",
            fontSize: "14px",
            marginTop: "8px",
            fontWeight: "bold",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {points}{" "}
          <span
            className="text-gray-300 font-normal"
            style={{ fontSize: "10px" }}
          >
            ({pointsCount})
          </span>
        </span>
      </>
    );
  }

  function valueFooter() {
    const valueCount = data.hands_statistic
      .filter((hand) => hand.contested)
      .reduce((acc, hand) => {
        console.log(hand);
        if (hand.pre !== null) acc += 1;
        if (hand.flop !== null) acc += 1;
        if (hand.turn !== null) acc += 1;
        if (hand.river !== null) acc += 1;
        return acc;
      }, 0);

    const value = data.match_results.value_quotient.value.toFixed(2);

    return (
      <>
        <span
          style={{
            color: value < 0 ? "#E06666" : "#5DC05E",
            display: "flex",
            fontSize: "14px",
            marginTop: "8px",
            fontWeight: "bold",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {value}{" "}
          <span
            className="text-gray-300 font-normal"
            style={{ fontSize: "10px" }}
          >
            ({valueCount})
          </span>
        </span>
      </>
    );
  }

  const defaultData = data.hands_statistic.map((hand, idx) => {
    return {
      hand: idx + 1,
      pocket_cards: hand.pocket_cards,
      community_cards: hand.community_cards,
      value: null,
      pre: hand.value_quotients[0] ? hand.value_quotients[0].toFixed(2) : null,
      flop: hand.value_quotients[1] ? hand.value_quotients[1].toFixed(2) : null,
      turn: hand.value_quotients[2] ? hand.value_quotients[2].toFixed(2) : null,
      river: hand.value_quotients[3]
        ? hand.value_quotients[3].toFixed(2)
        : null,
      aggression: hand.contested ? null : hand.chips_delta,
      points: hand.points,
    };
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("hand", {
      header: "#",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("pocket_cards", {
      header: "Hole",
      cell: (info) => {
        return (
          <div
            className="flex gap-0.5 sm:gap-2"
            style={{ width: "max-content" }}
          >
            {(info.getValue() ? info.getValue() : []).map((card) => (
              <SmallCard
                theme={"FOUR_COLOR"}
                rank={card[0] == "T" ? 10 : card[0]}
                suit={getSuit(card[1])}
              />
            ))}
          </div>
        );
      },
    }),
    columnHelper.accessor("community_cards", {
      header: "Board",
      cell: (info) => {
        return (
          <div
            className="flex gap-0.5 sm:gap-2"
            style={{ width: "max-content" }}
          >
            {(info.getValue() ? info.getValue() : []).map(
              (card) =>
                card && (
                  <SmallCard
                    rank={card[0] == "T" ? 10 : card[0]}
                    theme={"FOUR_COLOR"}
                    suit={getSuit(card[1])}
                  />
                )
            )}
          </div>
        );
      },
    }),
    // columnHelper.accessor("value", {
    //   header: "Value:",
    //   footer: (info) => (
    //     <>
    //       <span className="label">Value</span>
    //       <span
    //         className="value"
    //         style={{
    //           color:
    //             parseFloat(data.match_results.msr.points.value) > 0
    //               ? "#5DC05E"
    //               : "#E06666",
    //         }}
    //       >
    //         {parseFloat(data.match_results.value_quotient.value).toFixed(2)}{" "}
    //         <span
    //           className="text-gray-300 font-normal"
    //           style={{ fontFamily: "Play", fontSize: "12px", fontWeight: 400 }}
    //         >
    //           (avg.)
    //         </span>
    //       </span>
    //     </>
    //   ),
    // }),
    // columnHelper.accessor("pre", {
    //   header: "Pre",
    //   cell: (info) => {
    //     const value = info.getValue();
    //     if (!value) {
    //       return <span></span>;
    //     }

    //     return value >= 0 ? (
    //       <span style={{ color: "#5DC05E" }}>({value})</span>
    //     ) : (
    //       <span style={{ color: "#E06666" }}>({value})</span>
    //     );
    //   },
    // }),
    // columnHelper.accessor("flop", {
    //   header: "Flop",
    //   cell: (info) => {
    //     const value = info.getValue();
    //     if (!value) {
    //       return <span></span>;
    //     }

    //     return value >= 0 ? (
    //       <span style={{ color: "#5DC05E" }}>({value})</span>
    //     ) : (
    //       <span style={{ color: "#E06666" }}>({value})</span>
    //     );
    //   },
    // }),
    // columnHelper.accessor("turn", {
    //   header: "Turn",
    //   cell: (info) => {
    //     const value = info.getValue();
    //     if (!value) {
    //       return <span></span>;
    //     }

    //     return value >= 0 ? (
    //       <span style={{ color: "#5DC05E" }}>({value})</span>
    //     ) : (
    //       <span style={{ color: "#E06666" }}>({value})</span>
    //     );
    //   },
    // }),
    // columnHelper.accessor("river", {
    //   header: "River",
    //   cell: (info) => {
    //     const value = info.getValue();
    //     if (!value) {
    //       return <span></span>;
    //     }

    //     return value >= 0 ? (
    //       <span style={{ color: "#5DC05E" }}>({value})</span>
    //     ) : (
    //       <span style={{ color: "#E06666" }}>({value})</span>
    //     );
    //   },
    // }),
    columnHelper.accessor("aggression", {
      header: "Results",
      cell: (info) => {
        const aggression = info.getValue();
        if (!aggression) {
          const row = info.row.original;

          let value = 0;
          let counter = 0;
          if (row.pre) {
            value += parseFloat(row.pre);
            counter++;
          }
          if (row.flop) {
            value += parseFloat(row.flop);
            counter++;
          }
          if (row.turn) {
            value += parseFloat(row.turn);
            counter++;
          }
          if (row.river) {
            value += parseFloat(row.river);
            counter++;
          }

          value /= counter;
          value = value.toFixed(2);

          return value >= 0 ? (
            <span style={{ color: "#5DC05E", display: "flex" }}>
              <img className="w-3 h-3 mr-1 sm:h-5 sm:w-5" src="/coin.png" />{" "}
              {value}{" "}
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              >
                ({counter})
              </span>
            </span>
          ) : (
            <span style={{ color: "#E06666", display: "flex" }}>
              <img className="w-3 h-3 mr-1 sm:h-5 sm:w-5" src="/coin.png" />{" "}
              {value}{" "}
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              >
                ({counter})
              </span>{" "}
            </span>
          );
        }
        return aggression >= 0 ? (
          <span style={{ color: "#5DC05E", display: "flex" }}>
            <img className="w-3 h-4 mr-1 sm:h-7 sm:w-5" src="/lighting.png" />{" "}
            <span style={{ paddingRight: "8px" }}>{aggression}</span>
          </span>
        ) : (
          <span style={{ color: "#E06666", display: "flex" }}>
            <img className="w-3 h-4 mr-1 sm:h-7 sm:w-5" src="/lighting.png" />{" "}
            <span style={{ paddingRight: "8px" }}>{aggression}</span>
          </span>
        );
      },
      footer: (info) => {
        const aggressionCount = data.hands_statistic.filter(
          (hand) => !hand.contested
        ).length;

        const aggression = (
          data.match_results.aggression.total / aggressionCount
        ).toFixed(2);
        const value = parseFloat(
          data.match_results.value_quotient.value
        ).toFixed(2);

        return (
          <>
            <span
              style={{
                color: aggression < 0 ? "#E06666" : "#5DC05E",
                display: "flex",
                fontSize: "12px",
                fontWeight: "normal",
                marginBottom: "8px",
              }}
            >
              <img className="w-3 h-4 mr-1 sm:h-5 sm:w-5" src="/lighting.png" />{" "}
              {aggression}{" "}
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              ></span>{" "}
            </span>

            <span
              style={{
                color: value < 0 ? "#E06666" : "#5DC05E",
                display: "flex",
                fontSize: "12px",
                fontWeight: "normal",
              }}
            >
              <img className="w-3 h-3 mr-1 sm:h-5 sm:w-5" src="/coin.png" />{" "}
              {value}{" "}
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              ></span>{" "}
            </span>
          </>
        );
      },
    }),
    columnHelper.accessor("points", {
      header: "Points",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span style={{ color: "#5DC05E" }}>{0}</span>;
        }

        return value >= 0 ? (
          <span style={{ color: "#5DC05E" }}>{value}</span>
        ) : (
          <span style={{ color: "#E06666" }}>{value}</span>
        );
      },
      footer: (info) => (
        <>
          <span
            style={{
              color:
                data.match_results.msr.points.value < 0 ? "#E06666" : "#5DC05E",
              display: "flex",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            <img className="w-3 h-3 mr-1 sm:h-5 sm:w-5" src="/star.png" />{" "}
            {data.match_results.msr.points.value}{" "}
            <span
              className="text-gray-300 font-normal"
              style={{
                fontFamily: "Play",
                fontSize: "10px",
                fontWeight: 400,
              }}
            ></span>{" "}
          </span>
        </>
      ),
    }),
  ];

  const [tableData, setTableData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        style={{
          overflow: "auto",
          height: "400px",
          // minWidth: "600px",
        }}
      >
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={(e) => {
                  openLogs(reducedLogs[row.id]);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                <th colSpan={5}>
                  <div className="flex gap-8 justify-center">
                    <div>
                      <span className="flex gap-1 items-center">
                        <img src="/coin.png" class="w-3 h-3" />
                        <span>Value:</span>
                      </span>
                      <div>{valueFooter()}</div>
                    </div>
                    <div>
                      <span className="flex gap-1 items-center">
                        <img src="/lighting.png" class="w-3 h-4" />
                        <span>Aggression:</span>
                      </span>
                      <div>{aggressionFooter()}</div>
                    </div>
                    <div>
                      <span className="flex gap-1 items-center">
                        <img src="/star.png" class="w-4 h-4" />
                        <span>Points:</span>
                      </span>
                      <div>{pointsFooter()}</div>
                    </div>
                  </div>
                </th>
              </tr>
            ))}
          </tfoot>
        </StyledTable>
        <div className="h-4" />
      </div>

      {showLog && (
        <LogsAlert logs={currentLog} onClose={() => setShowLog(false)} />
      )}
    </>
  );
};
