const heartsColors = {
  FOUR_COLOR: {
    color: '#EE2828',
    shadowColor: '#EE2828'  
  },
  STANDARD: {
    color: '#EE2828',
    shadowColor: '#EE2828' 
  }
}

const diamondsColors = {
  FOUR_COLOR: {
    color: '#287FE0',
    shadowColor: '#2994DD'  
  },
  STANDARD: {
    color: '#EE2828',
    shadowColor: '#EE2828' 
  }
}

const clubsColors = {
  FOUR_COLOR: {
    color: '#59BD31',
    shadowColor: '#59B335'  
  },
  STANDARD: {
    color: '#2C2C2C',
    shadowColor: '#2C2C2C' 
  }
}

const spadesColors = {
  FOUR_COLOR: {
    color: '#2C2C2C',
    shadowColor: '#2C2C2C'  
  },
  STANDARD: {
    color: '#2C2C2C',
    shadowColor: '#2C2C2C' 
  }
}

export const suitColor = (suit, theme) => {
  const themeName = theme !== 'FOUR_COLOR' ? 'STANDARD' : 'FOUR_COLOR'

  return (
      suit === 'hearts'   ? heartsColors[themeName]
    : suit === 'diamonds' ? diamondsColors[themeName]
    : suit === 'clubs'    ? clubsColors[themeName]
    : suit === 'spades'   ? spadesColors[themeName]
    :                       null
  )
}
