import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import apolloLogger from "apollo-link-logger";
import browserHistory from "browserHistory";
import { message } from "antd";
import { ensureLogout } from "utils/auth";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { createUploadMiddleware } from "apollo-absinthe-upload-link";
import { Socket as PhoenixSocket } from "phoenix";
import { hasSubscription } from "@jumpn/utils-graphql";
import store from "store";

const wsLink = createAbsintheSocketLink(
  AbsintheSocket.create(new PhoenixSocket("ws://localhost:4000/socket"))
);
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });
const uploadLink = createUploadMiddleware({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const transportLink = new ApolloLink.split(
  (operation) => hasSubscription(operation.query),
  wsLink,
  httpLink
);

const cache = new InMemoryCache({
  // addTypename: false,
  dataIdFromObject: (obj) => obj.id,
});
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const dispatch = store.dispatch;
  if (networkError) {
    switch (networkError.statusCode) {
      case 401: {
        message.warning("You need to be logged in to view the page.", 10);
        ensureLogout(dispatch);
        browserHistory.push("/sign-in");
        break;
      }

      case 500: {
        message.warning(
          "We are experiencing issues with the server. Please reload the page.",
          10
        );
        break;
      }

      default: {
        message.error("Something went wrong. Please reload the page.", 10);
        break;
      }
    }
  }
  //browserHistory.push('/sign-in')
  if (graphQLErrors) {
    graphQLErrors.forEach(({ code }) => {
      switch (code) {
        case 401: {
          browserHistory.push("/sign-in");
          // message.warning("You need to be logged in.", 10)
          ensureLogout(dispatch);
          break;
        }

        case 403: {
          browserHistory.push("/sign-in");
          message.warning("Access denied", 10);
          ensureLogout(dispatch);
          break;
        }

        default:
          break;
      }
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(
    process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY
  );

  if (token !== null) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  return headers;
});

const historyLink = setContext((_, previousContext) => ({
  history: browserHistory,
}));
const reduxLink = setContext((_, previousContext) => ({
  dispatch: store.dispatch,
}));

const client = new ApolloClient({
  link: ApolloLink.from([
    apolloLogger,
    historyLink,
    reduxLink,
    errorLink,
    authLink,
    uploadLink,
    transportLink,
  ]),
  cache,
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
export default client;
