import React from 'react'

const Pull = ({
  direction,
  children,
  style,
  ...props
}) => (
  <div
    className="ant-pull"
    style={{
      float: direction,
      ...style
    }}
    {...props}
  >
    {children}
  </div>
)

Pull.defaultProps = {
  direction: 'left'
}

export default Pull
