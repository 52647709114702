import PropTypes from 'prop-types'

const Loading = ({ data, refetch, renderSpinner, loading, renderError, render, networkStatus, error }) => {
  // See https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-networkStatus
  const isRefetch = networkStatus === 4

  return loading && !isRefetch ? (
    renderSpinner()
  ) : error ? (
    renderError(error)
  ) : (
    render(data, refetch)
  )
}

Loading.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  renderError: PropTypes.func.isRequired,
  renderSpinner: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  networkStatus: PropTypes.number
}

export default Loading
