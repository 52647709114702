import React from 'react'

const Box = ({
  children,
  top,
  right,
  bottom,
  left,
  vertical,
  horizontal,
  all,
  relative,
  absolute,
  outside,
  inline,
  style,
  ...props
}) => {
  const sProperty = outside ? 'margin' : 'padding'

  return <div
    className="ant-box"
    style={{
      [`${sProperty}Top`]    : `${top    || vertical   || all}px`,
      [`${sProperty}Right`]  : `${right  || horizontal || all}px`,
      [`${sProperty}Bottom`] : `${bottom || vertical   || all}px`,
      [`${sProperty}Left`]   : `${left   || horizontal || all}px`,
      display                : inline ? 'inline' : 'block',
      position: absolute ? 'absolute' : 'relative',
      ...style
    }}
    {...props}
  >
    {children}
  </div>
}

Box.defaultProps = {
  outside: false,
  inline: false,
  all: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  vertical: 0,
  horizontal: 0
}

export default Box
