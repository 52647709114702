import React from "react";
import Big from "./big";
import Small from "./small";
export default ({ ...props }) => {
  return (
    <>
      <div className="block md:hidden">
        <Small {...props} />
      </div>

      <div className="hidden md:block">
        <Big {...props} />
      </div>
    </>
  );
};
