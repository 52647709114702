import React from 'react';
import styled from "styled-components";

export const SuitContainer = styled.div`
  display: flex;
  align-items: center;
  //justify-content: center;
  //width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin-top: 2px;
  font-size: ${props => props.size}px;
  //text-align: center;
  
  color: ${props => props.color};
`

const Suit = ({suit, shadowColor, size}) => {
  const getRenderedSuitUnicode = () => {
    switch(suit) {
      case 'spades':
        return '♠'
      case 'clubs':
        return '♣'
      case 'diamonds':
        return '♦'
      case 'hearts':
        return '♥';
      default:
        console.error("You use wrong suit")
    }
  }

  return <SuitContainer size={size} color={shadowColor}>{getRenderedSuitUnicode()}</SuitContainer>
}

export default Suit
