import React from "react";
import * as R from "ramda";
import styled from "styled-components";
import { isPresent, flattenAppend } from "utils/common";
import Text from "components/Text";
import Card from "scenes/Games/Show/Card/Mini";

const CardsWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  padding: 10px 0;
  margin-bottom: -6px;
`;

const CardSpacer = styled.div`
  margin-left: 15px;
  display: inline;
`;

const Red = styled.span`
  color: #df4128;
  font-size: 18px !important;
  font-family: Play;
`;
const Green = styled.span`
  color: #5dc05e;
  font-size: 18px !important;
`;

const colsKeys = {
  number: "number",
  pocket_cards: "pocket_cards",
  community_cards: "community_cards",
  value: "value",
  aggression: "aggression",
  points: "points",
};

const extraColsKeys = {
  flop: "flop",
  pre: "pre",
  turn: "turn",
  river: "river",
};

const cardMapper = (card) => {
  if (!card) {
    return null;
  }

  const rank = card[0];
  const suit = card[1];

  const rank_map = {
    A: "ace",
    K: "king",
    Q: "queen",
    J: "jack",
    T: 10,
    "9": 9,
    "8": 8,
    "7": 7,
    "6": 6,
    "5": 5,
    "4": 4,
    "3": 3,
    "2": 2,
  };

  const suit_map = {
    h: "hearts",
    d: "diamonds",
    c: "clubs",
    s: "spades",
  };

  return { rank: rank_map[rank], suit: suit_map[suit] };
};

const SuitTheme = "FOUR_COLOR";

export const renderAsFixed = (value, withBrackets) => {
  const renderedValue = !value || isNaN(value) ? value : value.toFixed(2);
  return <span>{withBrackets ? `(${+renderedValue})` : +renderedValue}</span>;
};

export const renderValueQuotient = (value, withBrackets = false) =>
  value ? (
    value < 0 ? (
      <Red>{renderAsFixed(value, withBrackets)}</Red>
    ) : (
      <Green>{renderAsFixed(value, withBrackets)}</Green>
    )
  ) : null;

export const renderAggression = (value, agressed) =>
  agressed ? (
    value < 0 ? (
      <Red>({value})</Red>
    ) : (
      <Green>
        ({value > 0 ? "+" : ""}
        {value})
      </Green>
    )
  ) : null;

export const columns = (logs, openLogs) => {
  //   const mockLog = [
  //     {
  //         "type": "init",
  //         "message": "Hand #2 started"
  //     },
  //     {
  //         "type": "streetUpdate",
  //         "stage": "Start",
  //         "cards": [
  //             {
  //                 "suit": "diamonds",
  //                 "rank": "queen",
  //                 "closed": false
  //             },
  //             {
  //                 "suit": "clubs",
  //                 "rank": 7,
  //                 "closed": false
  //             }
  //         ]
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:33",
  //         "username": "jos",
  //         "message": "Placed SB 500"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:33",
  //         "username": "wannesmatthys",
  //         "message": "Placed BB 1,000"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:34",
  //         "username": "jos",
  //         "message": "Raised to 2,000"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:35",
  //         "username": "wannesmatthys",
  //         "message": "Raised to 3,000"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:35",
  //         "username": "jos",
  //         "message": "Raised to 4,000"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:37",
  //         "username": "wannesmatthys",
  //         "message": "Raised to 41,000"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:38",
  //         "username": "jos",
  //         "message": "Called"
  //     },
  //     {
  //         "type": "streetUpdate",
  //         "stage": "flop",
  //         "cards": [
  //             {
  //                 "suit": "spades",
  //                 "rank": 8,
  //                 "closed": false,
  //                 "highlighted": false
  //             },
  //             {
  //                 "suit": "clubs",
  //                 "rank": "queen",
  //                 "closed": false,
  //                 "highlighted": false
  //             },
  //             {
  //                 "suit": "clubs",
  //                 "rank": "ace",
  //                 "closed": false,
  //                 "highlighted": false
  //             }
  //         ],
  //         "time": "06:42:38"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:39",
  //         "username": "wannesmatthys",
  //         "message": "Checked"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:40",
  //         "username": "jos",
  //         "message": "Checked"
  //     },
  //     {
  //         "type": "streetUpdate",
  //         "stage": "turn",
  //         "cards": [
  //             {
  //                 "suit": "spades",
  //                 "rank": 10,
  //                 "closed": false,
  //                 "highlighted": false
  //             }
  //         ],
  //         "time": "06:42:40"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:41",
  //         "username": "wannesmatthys",
  //         "message": "Checked"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:42",
  //         "username": "jos",
  //         "message": "Checked"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:42",
  //         "username": "wannesmatthys",
  //         "message": "Checked"
  //     },
  //     {
  //         "type": "streetUpdate",
  //         "stage": "river",
  //         "cards": [
  //             {
  //                 "suit": "spades",
  //                 "rank": "jack",
  //                 "closed": false,
  //                 "highlighted": false
  //             }
  //         ],
  //         "time": "06:42:42"
  //     },
  //     {
  //         "type": "action",
  //         "time": "06:42:44",
  //         "username": "jos",
  //         "message": "Checked"
  //     },
  //     {
  //         "type": "showdown",
  //         "cards": [
  //             {
  //                 "suit": "diamonds",
  //                 "rank": "queen",
  //                 "closed": false
  //             },
  //             {
  //                 "suit": "clubs",
  //                 "rank": 7,
  //                 "closed": false
  //             }
  //         ],
  //         "stage": "Your hand:"
  //     },
  //     {
  //         "type": "showdown",
  //         "cards": [
  //             {
  //                 "suit": "spades",
  //                 "rank": 8,
  //                 "closed": false,
  //                 "highlighted": false
  //             },
  //             {
  //                 "suit": "clubs",
  //                 "rank": "queen",
  //                 "closed": false,
  //                 "highlighted": true
  //             },
  //             {
  //                 "suit": "clubs",
  //                 "rank": "ace",
  //                 "closed": false,
  //                 "highlighted": true
  //             },
  //             {
  //                 "suit": "spades",
  //                 "rank": 10,
  //                 "closed": false,
  //                 "highlighted": true
  //             },
  //             {
  //                 "suit": "spades",
  //                 "rank": "jack",
  //                 "closed": false,
  //                 "highlighted": true
  //             }
  //         ],
  //         "stage": "Community cards:"
  //     },
  //     {
  //         "type": "final",
  //         "message": "Winner: wannesmatthys (Pot: 83000)",
  //         "time": "06:42:44"
  //     }
  // ];
  return [
    {
      key: colsKeys.number,
      title: "Hand",
      width: 50,
      render: (_, __, rowIndex) => {
        return (
          <Text
            size="18px"
            fontSizeDesktop="24px"
            playFont
            bold
            padding="0 10px 0 0"
            // pointer={true}
            // hover={true}

            // underline={true}
            // textColor={"#eb56a7"}
            // onClick={() => openLogs(mockLog)}
          >
            {rowIndex + 1}
          </Text>
        );
      },
    },
    {
      key: colsKeys.pocket_cards,
      title: "Hole cards",
      width: 100,
      render: (record, _index) => {
        const first_card = cardMapper(record.pocket_cards[0]);
        const second_card = cardMapper(record.pocket_cards[1]);

        return (
          <CardsWrapper>
            <Card
              width={45}
              height={55}
              scale={2}
              theme={SuitTheme}
              rank={first_card.rank}
              suit={first_card.suit}
            />
            <Card
              width={45}
              height={55}
              scale={2}
              theme={SuitTheme}
              rank={second_card.rank}
              suit={second_card.suit}
            />
          </CardsWrapper>
        );
      },
    },
    {
      key: colsKeys.community_cards,
      title: "Board cards",
      width: 250,
      render: (record, _index) => {
        const first_card = cardMapper(record.community_cards[0]);
        const second_card = cardMapper(record.community_cards[1]);
        const third_card = cardMapper(record.community_cards[2]);
        const fourth_card = cardMapper(record.community_cards[3]);
        const fifth_card = cardMapper(record.community_cards[4]);

        return (
          <div style={{ paddingLeft: "1rem" }}>
            <CardsWrapper>
              {first_card && (
                <Card
                  width={40}
                  height={55}
                  scale={0.85}
                  theme={SuitTheme}
                  rank={first_card.rank}
                  suit={first_card.suit}
                />
              )}
              {second_card && (
                <Card
                  width={40}
                  height={55}
                  scale={0.85}
                  theme={SuitTheme}
                  rank={second_card.rank}
                  suit={second_card.suit}
                />
              )}
              {third_card && (
                <Card
                  width={40}
                  height={55}
                  scale={0.85}
                  theme={SuitTheme}
                  rank={third_card.rank}
                  suit={third_card.suit}
                />
              )}
              {fourth_card && (
                <Card
                  width={40}
                  height={55}
                  scale={0.85}
                  theme={SuitTheme}
                  rank={fourth_card.rank}
                  suit={fourth_card.suit}
                />
              )}
              {fifth_card && (
                <Card
                  width={40}
                  minWidth={40}
                  height={55}
                  scale={0.85}
                  theme={SuitTheme}
                  rank={fifth_card.rank}
                  suit={fifth_card.suit}
                />
              )}
            </CardsWrapper>
          </div>
        );
      },
    },

    {
      key: "value",
      title: "Value:",
      width: 75,
      render: (record, _index) => <></>,
    },
    {
      key: "preflop",
      title: "Pre",
      width: 75,
      render: (record, _index) => (
        <Text size="18px" fontSizeDesktop="24px" playFont bold>
          {renderValueQuotient(record.value_quotients[0], true)}
        </Text>
      ),
    },
    {
      key: "flop",
      title: "Flop",
      width: 75,
      render: (record, _index) => (
        <Text size="18px" fontSizeDesktop="24px" playFont white bold>
          {renderValueQuotient(record.value_quotients[1], true)}
        </Text>
      ),
    },
    {
      key: "turn",
      title: "Turn",
      width: 75,
      render: (record, _index) => (
        <Text size="18px" fontSizeDesktop="24px" playFont white bold>
          {renderValueQuotient(record.value_quotients[2], true)}
        </Text>
      ),
    },
    {
      key: "river",
      title: "River",
      width: 75,
      render: (record, _index) => (
        <Text size="18px" fontSizeDesktop="24px" playFont white bold>
          {renderValueQuotient(record.value_quotients[3], true)}
        </Text>
      ),
    },
    {
      key: colsKeys.aggression,
      title: "Aggression",
      width: 150,
      render: (record, _index) => (
        <Text size="18px" fontSizeDesktop="24px" playFont bold>
          {renderAggression(record.chips_delta, !record.contested)}
        </Text>
      ),
    },
    {
      key: colsKeys.points,
      title: "Points History",
      width: 150,
      render: (record, _index) => (
        <Text size="18px" fontSizeDesktop="24px" playFont bold>
          {record.points < 0 ? (
            <Red>{record.points}</Red>
          ) : (
            <Green>{record.points}</Green>
          )}
        </Text>
      ),
    },
  ];
};

export const groupTableColumnsData = R.reduce(
  (acc, column) => {
    const getCompactData = R.pick(["key", "title"]);

    if (isPresent(column.children)) {
      return {
        mainColumns: R.append(
          {
            colSpan: R.length(column.children),
            ...getCompactData(column),
          },
          acc.mainColumns
        ),
        nestedColumns: flattenAppend(
          column.children.map(getCompactData),
          acc.nestedColumns
        ),
        columnsWithRender: flattenAppend(
          column.children,
          acc.columnsWithRender
        ),
      };
    }

    return R.merge(acc, {
      mainColumns: R.append(
        {
          rowSpan: 2,
          ...getCompactData(column),
        },
        acc.mainColumns
      ),
      columnsWithRender: R.append(column, acc.columnsWithRender),
    });
  },
  {
    mainColumns: [],
    nestedColumns: [],
    columnsWithRender: [],
  }
);
