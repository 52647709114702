import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import BottomAlignedVirtualList from "components/BottomAlignedVirtualList";
import Card from "scenes/Games/Show/Card/Mini";
import Suit, { SuitContainer } from "../Card/Mini/Suits";

const Time = styled.span`
  color: #999;
`;
const UserName = styled.span`
  color: #888;
`;

const Message = styled.span`
  color: #777;
`;

const CardItem = styled(Message)`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const FlexCardColumn = styled.div`
  display: flex;
  gap: 3px;
`;

const LogAction = ({ time, username, message, style }) => (
  <Row style={style}>
    <Col span={5}>
      <Time>{time}</Time>
    </Col>
    <Col span={7}>
      <UserName>
        {username.length > 9 ? `${username.substring(0, 6)}...` : username}
      </UserName>
    </Col>
    <Col span={12}>
      <Message>{message}</Message>
    </Col>
  </Row>
);

const LogStreetUpdate = ({ time, stage, style, cards = [] }) => (
  <Row style={style}>
    {time !== undefined ? (
      <>
        <Col span={5}>
          <Time>{time}</Time>
        </Col>
        <Col span={7}>{stage}</Col>
      </>
    ) : (
      <>
        <Col span={12} style={{ fontWeight: "bold" }}>
          {stage}
        </Col>
      </>
    )}

    {cards.length > 0 && (
      <Col span={36} style={{ borderBottom: "1px solid #dadada" }}>
        <FlexCardColumn>
          {cards.map(
            (card) =>
              Number.isInteger(card.rank) ? (
                <CardItem key={card.rank + "-" + card.suit} scale={1}>
                  <span>{card.rank}</span>
                  <Suit suit={card.suit} size={14} />
                </CardItem>
              ) : (
                <CardItem key={card.rank + "-" + card.suit} scale={1}>
                  <span>{card.rank[0].toUpperCase()}</span>
                  <Suit suit={card.suit} size={14} />
                </CardItem>
              ),
            cards
          )}
        </FlexCardColumn>
      </Col>
    )}
  </Row>
);

const SysRow = styled(Row)`
  color: #888;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const InitRow = styled(SysRow)`
  padding-top: 5px;
`;
const Divider = styled.div`
  border-bottom: 3px solid #dadada;
`;
const LogInit = ({ message, style }) => (
  <InitRow style={style}>
    <Col style={{ borderBottom: "1px solid #dadada" }}>{message}</Col>
  </InitRow>
);

const LogFinal = ({ message, style }) => (
  <SysRow style={{ ...style, paddingTop: "10px" }}>
    <Col>{message}</Col>
  </SysRow>
);
const logMapping = {
  action: LogAction,
  init: LogInit,
  final: LogFinal,
  streetUpdate: LogStreetUpdate,
  showdown: LogStreetUpdate,
  divider: Divider,
};
const LogItem = ({ type, ...props }) => {
  const LogComponent = logMapping[type];
  return <LogComponent {...props} />;
};

const heightMapping = {
  divider: 20,
  init: 40,
  final: 40,
  action: 25,
  streetUpdate: 40,
  showdown: 30,
};

const heightCalculator = ({ item }) => heightMapping[item.type];

export default ({ items, ...props }) => {
  return (
    <BottomAlignedVirtualList
      {...props}
      items={items}
      style={{ paddingLeft: 7, paddingRight: 7 }}
      rowHeight={heightCalculator}
      renderItem={LogItem}
    />
  );
};
