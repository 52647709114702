import React from "react";
import Layout from "scenes/shared/Layout";
import { Row, Col } from "antd";
import Spin from "components/Spin";

export default () => (
  <Layout>
    <Layout.Header />
    <Layout.Content>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
        }}
      >
        <Col>
          <Spin />
        </Col>
      </Row>
    </Layout.Content>
  </Layout>
);
