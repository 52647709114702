import types from 'constants/actionTypes'

export const setToken = (dispatch, token) => {
  localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY, token)
  dispatch({ type: types.SET_AUTH_TOKEN, payload: { token } })
}

export const ensureLogout = (dispatch) => {
  dispatch({ type: types.SET_AUTH_TOKEN, payload: { token: null } })
  localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY)
}
