import React from "react";

const Star = ({ color, ...props }) => (
  <svg width={16} height={16} viewBox="0 0 15 15" {...props}>
    <title>Shape Copy 5</title>
    <path
      d="M13.243 5.971L10.51 8.808l.645 4.007a.796.796 0 0 1-.288.752.693.693 0 0 1-.76.059L6.73 11.734l-3.377 1.892a.687.687 0 0 1-.76-.058.794.794 0 0 1-.287-.753L2.95 8.81.218 5.971a.805.805 0 0 1-.183-.788.737.737 0 0 1 .583-.524l3.776-.585L6.083.43A.717.717 0 0 1 6.73 0c.275 0 .526.166.647.429l1.69 3.645 3.776.585a.733.733 0 0 1 .582.524.804.804 0 0 1-.182.788z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

Star.defaultProps = {
  color: "#fff",
};

export default Star;
