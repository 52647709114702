const syncActions = [
  'NOOP',
  'MATCH_START',
  'GAME_START',
  'ROOM_STATE',
  'TEAM_SCORE',
  'UPDATE_SCOREBOARD',
  'PRESENCE_STATE',
  'PRESENCE_DIFF',
  'PERIOD_SHIFT',
  'UPDATE_RAISE_AMOUNT',
  'CLEAR_RAISE_AMOUNT',
  'RAISE_ALL_IN',
  'MATCH_FINISH',
  'SLIDER_UPDATE_RAISE_AMOUNT',
  'SET_AUTH_TOKEN',
  'CLEAR_MATCH_SHOW_STATE',
  'TOGGLE_TIME_BANK',
  'UPDATE_TIME_BANKS',
  'TOGGLE_AUDIO'
]

export default syncActions
