import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import * as breakpoints from "constants/sizes";

export const Wrapper = styled.div`
  width: 100%;
  padding: 4px;
  @media all and (min-width: 1200px) {
    padding: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: block;
  margin-right: 0;
  border-radius: 11px;
  background: white;
  width: fit-content;

  @media (max-width: 768px) {
    margin: 0 auto;
    margin-top: 16px;
  }
`;

export const Button = styled.div`
  display: inline-flex;
  background-color: white;

  font-family: Play;
  font-size: 18px;
  font-weight: 500;
  color: #eb56a7;
  padding: 8px 16px;
  border: 2px solid white;

  border-radius: 11px;

  cursor: pointer;
  transition: all 300ms ease-out;

  &:nth-child(2) {
    margin-left: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #eb56a7;
      color: white;
    `}
`;

export const TableRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
`;

export const TableCell = styled.div`
  height: ${(props) => props.height || "70px"};
  //text-align: ${(props) => props.align};
  justify-content: ${(props) => props.align};
  flex: ${(props) => props.flex || 1};
  
  @media all and (min-width: ${breakpoints.desktop}) {    
    height: ${(props) => props.height || "100px"};
  }

  @media (max-width: 768px) {
    font-size: 16px !important;
  }
`;

export const TableCellContent = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props.padding || 0};
  font-size: 24px;
  color: ${(props) => (props.color !== undefined ? props.color : `black`)};

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 18px !important;
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
    gap: 10px;
  }
`;

export const TableContainer = styled.div`
  padding: 20px;
  background-color: #fff;

  @media all and (min-width: ${breakpoints.desktop}) {
    padding: 30px;
  }
`;

export const HandLog = {
  Root: styled.table`
    table-layout: fixed;
    width: calc(100% - 8px);
    border-radius: 10px;

    ${(props) =>
      props.negativeOffset &&
      css`
        position: relative;
      `}
    
    @media all and (min-width: ${breakpoints.desktop}) {
      width: calc(100% - 40px);
    }
    
    ${(props) =>
      props.isHeader &&
      css`
        width: 100%;

        @media all and (min-width: ${breakpoints.desktop}) {
          width: 100%;
        }
      `}
  `,
  Header: styled.thead`
    background-color: #1e2e37;
    border-radius: 10px;
    font-size: 16px !important;
    tr:first-child > :first-child::before,
    tr:first-child > :last-child::after {
      content: unset;
    }
  `,
  HeaderCell: styled.th`
    position: relative;
    width: ${(props) => props.width};
    text-align: center;
    padding: 0 !important;
    border-right: 2px white solid;
    font-size: 16px !important;

    span {
      font-size: 16px !important;
      color: white !important;
    }

    ${(props) => {
      if (props.compact)
        return css`
          background-color: #959595;
          margin-right: 1px;
          line-height: 1.4;
          border-right: 2px white solid;
        `;
    }}
  `,
  TableBodyWrapper: styled.div`
    height: ${(props) => props.heightLimit};

    .ps__rail-x {
      display: none !important;
    }

    .ps__rail-y {
      opacity: 1 !important;
      background-color: transparent !important;
      right: -2px !important;
    }

    .ps__thumb-y {
      box-sizing: border-box;
      border-radius: 0 !important;
      width: 5px !important;
      background-color: #bcbcbc !important;
      border-top: 4px solid #fff;

      &:hover {
        cursor: pointer;
      }
    }

    @media all and (min-width: ${breakpoints.desktop}) {
      .ps__thumb-y {
        width: 30px !important;
      }

      .ps__rail-y {
        right: 3px !important;
      }
    }
  `,
  TableBodyRow: styled.tr`
    vertical-align: middle;
    background-color: #f6f7f7;
    border-bottom: 2px solid #fff;

    td {
      border-right: 2px solid #fff;
    }
  `,
  ResultsTable: styled.table`
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;

    @media all and (min-width: ${breakpoints.desktop}) {
      margin-top: 20px;
    }
  `,
  ResultSection: styled.td`
    text-align: center;
    height: 60px;
  `,
  ResultTitle: styled.div`
    font-family: Play;
    font-size: 18px;
    font-weight: bold;
  `,
  ResultRow: styled.tr`
    border-right: 7px solid #fff;

    @media all and (min-width: ${breakpoints.desktop}) {
      border-right: 60px solid #fff;
    }
  `,
  ScrollOffset: styled.th`
    width: 8px;

    @media all and (min-width: ${breakpoints.desktop}) {
      width: 40px;
    }
  `,
};

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
