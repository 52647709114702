import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import pinkButtonBG from "../assets/images/PlayButtonIcon.jpg";
import darkButtonBG from "../assets/images/SecondButtonIcon.jpg";
import history from "browserHistory";

const ButtonWrapper = styled("button")`
  color: white;
  background-color: ${(props) => (props.bg == "dark" ? "#1E2E37" : "#EB56A7")};
  padding: 10px;
  width: 100%;
  font-size: 26px;
  border-radius: 16px;
  font-family: ContraxHV;
  font-style: normal;
  font-weight: bold;
  border: none;
  cursor: pointer;
`;

const LargeButton = ({ children, bg, to, size, ...props }) => {
  const onClickHandler = to ? () => history.push(to) : props.onClick;
  return (
    <ButtonWrapper onClick={onClickHandler} {...props} bg={bg} btnsize={size}>
      {children}
    </ButtonWrapper>
  );
};

export default LargeButton;
