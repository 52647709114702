import React from 'react'
import * as PropTypes from 'prop-types'
import { noop } from 'utils/common'

const coerceToUndef = (v) => v === 'undefined' ? undefined : v
const buildChannelName = (topic, subtopic) => coerceToUndef(subtopic) ? `${topic}:${subtopic}` : null

export default class Channel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      channel: null
    }
    this.onLeave = this.props.onLeave.bind(this, props)
  }

  componentDidMount() {
    const [topic, subtopic] = this.props.name.split(":")
    const name = buildChannelName(topic, subtopic)
    const password = this.props.password || "";

    if (name && !this.state.channel) {
      const channel = this.context.socket.channel(name, {password: password})
      this.setState({ channel })
      const { onJoinSuccess, onJoinError, onJoinTimeout } = this.props
      
      channel.join()
            .receive('ok', onJoinSuccess.bind(this, this.props))
            .receive('error', onJoinError.bind(this, this.props))
            .receive('timeout', onJoinTimeout.bind(this, this.props))

      this.props.onListen.forEach(([ev, cb]) => channel.on(ev, cb.bind(this, { channel, ...this.props})))
    }
  }

  componentWillReceiveProps(nextProps) {
    const [topic, subtopic] = this.props.name.split(":")
    const name = buildChannelName(topic, subtopic)
    const [nextTopic, nextSubtopic] = nextProps.name.split(":")
    const nextName = buildChannelName(nextTopic, nextSubtopic)

    const cond =
      (nextName && (name !== nextName)) ||
      ((name === nextName) && !this.state.channel)

    if (cond) {
      const channel = this.context.socket.channel(nextName)
      this.setState({ channel })
      const { onJoinSuccess, onJoinError, onJoinTimeout } = nextProps

      channel.join()
            .receive('ok', onJoinSuccess.bind(this, nextProps))
            .receive('error', onJoinError.bind(this, nextProps))
            .receive('timeout', onJoinTimeout.bind(this, nextProps))

      this.props.onListen.forEach(([ev, cb]) => channel.on(ev, cb.bind(this, { channel, ...nextProps})))
    }
  }

  componentWillUnmount() {
    this.state.channel.leave().receive('ok', this.onLeave)
  }

  render() {
    return (
      <React.Fragment>
        {this.props.render({ channel: this.state.channel })}
      </React.Fragment>
    )
  }
}

Channel.contextTypes = {
  socket: PropTypes.object
}

Channel.defaultProps = {
  onJoinSuccess: noop,
  onJoinError: noop,
  onJoinTimeout: noop,
  onListen: [],
  onLeave: noop
}
