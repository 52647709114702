import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import * as breakpoints from "constants/sizes";

export const Wrapper = styled.div`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  &:last-child {
    margin-right: 0;
  }
`;

export const Button = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 50px;
  
  background-color:  #aee0f7;

  font-family: Play;
  font-size: 28px;
  font-weight: bold;

  color: ${colors.gunmetal};

  white-space: nowrap;

  cursor: pointer;
  transition: all 300ms ease-out;
  
  &:nth-child(2) {
    margin-left: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #fff;
    `}
  
  @media all and (min-width: ${breakpoints.desktop}) {
    width: 320px;
    height: 70px;
    font-size: 40px;
  }
`;

export const TableContainer = styled.div`
  overflow: auto;
`;

export const TableRoot = styled.table`
  position: relative;
  top: -4px;
`;

export const RowSeparator = styled.tr`
  height: ${(props) => props.size || "20px"};
`;

export const HeaderColumnSeparator = styled.th`
  height: ${(props) => props.size || "35px"};
`;

export const ColumnSeparator = styled.td`
  width: ${(props) => props.size || "35px"};
`;

export const HeaderCell = styled.th`
  width: ${(props) => props.width || "150px"};
  height: 60px;
  z-index: 10;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
`;

export const TableCell = styled.td`
  position: relative;
  height: ${(props) => props.height || "70px"};
  text-align: ${(props) => props.textAlign};
  padding: 0;

  @media all and (min-width: ${breakpoints.desktop}) {
    height: ${(props) => props.height || "100px"};
  }
`;

export const TableCellContent = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props.padding || 0};
  font-family: Play;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
`;

export const ExitButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 50px;
  box-shadow: inset 0 0 31px 12px rgba(41, 5, 5, 0.43);
  border: 4px solid ${colors.pink};
  background-image: linear-gradient(to top, #d94984 0%, #ea69a2 100%);

  cursor: pointer;
`;

const croppedBorder = css`
  position: absolute;
  bottom: 0;

  content: "";
  width: 4px;
  height: 60%;
  background-color: ${colors.pink};
`;
