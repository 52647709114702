import React from 'react'
import styled from 'styled-components'

const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-family:"Houschka Pro"
`

export default () =>
  <TextWrapper>
    <p>Sorry, it appears you are using a browser that is not currently supported.</p>
    <p>Please use the latest version of the following: <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>, <a href="https://support.apple.com/downloads">Safari</a>, <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>.</p>
  </TextWrapper>
