import React from 'react'
import * as PropTypes from 'prop-types'

class ConnectionSensor extends React.Component {
  state = { status: "connecting" }

  componentDidMount() {
    const { socket } = this.context
    socket.onOpen(this.setStatus)
    socket.onError(this.setStatus)
    socket.onClose(this.setStatus)
    this.setStatus()
  }

  setStatus = () => {
    let status = this.context.socket.connectionState()
    if (status === "open" && this.props.channel && !this.props.channel.canPush()) {
      status = "connecting"
    }
    this.setState({ status })
  }

  render() {
    const { status } = this.state

    return this.props.children({ status })
  }
}

ConnectionSensor.contextTypes = {
  socket: PropTypes.object
}
export default ConnectionSensor
