import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { isNilOrEmpty, isPresent } from "utils/common";
import Text from "components/Text";
import { SizeSensor } from "libreact/lib/SizeSensor";
import { Center, HandLog } from "scenes/Games/Show/PostMatchStatistic/ui";
import PerfectScrollbar from "react-perfect-scrollbar";
import Spin from "components/Spin";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import styled from "styled-components";
import { Table } from "antd";
import LogList from "../../../LogsAlert/LogList";
import LogsAlert from "../../../LogsAlert";
import { renderAggression, renderValueQuotient } from "./utils";
import SmallCard from "scenes/Games/Show/Card/Mini/Community";
import { columns as oldColumns } from "./utils";

const tableWidth = "100%";

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  thead tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  thead tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  tfoot tr:first-child th:first-child {
    border-bottom-left-radius: 10px;
  }

  tfoot tr:first-child th:last-child {
    border-bottom-right-radius: 10px;
  }

  thead {
    border-radius: 16px;
    top: -8px;
    z-index: 2;
    position: sticky;
    background-color: white;
  }

  thead tr {
    background: #1e2e37;
    color: #fff;
    padding: 5px;
    border-radius: 16px;

    th {
      padding: 10px;
      font-weight: semibold;
      text-align: center;
      border-right: 2px solid #fff;
    }
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7) {
      border-right: none;
      color: #c7cbcd;
    }

    th:nth-child(8) {
      color: #c7cbcd;
    }

    th:last-child {
      border-right: none;
    }

    th:nth-child(4) {
      border-right: none;
    }
  }

  tfoot {
    th {
      padding: 20px;
    }
    z-index: 2;
    position: sticky;
    bottom: -8px;
    background-color: white;
  }

  tbody {
    background: #f6f7f7;
    width: 100%;

    tr:hover {
      background: #e8e8e8;
      cursor: pointer;
    }

    td {
      padding: 10px;
      text-align: center;
      color: #566770;
      font-size: 18px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    td:last-child {
      border-right: none;
    }

    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      border-right: none;
    }
  }

  tfoot {
    background: #1e2e37;
    color: #fff;

    th {
      text-align: center;

      span.label {
        font-size: 14px;
        color: #fff;
        padding-bottom: 5px;
        display: block;
      }

      span.value {
        display: block;
        font-size: 18px;
      }
    }
  }
`;

const getSuit = (s) => {
  if (s === "c") return "clubs";
  if (s === "d") return "diamonds";
  if (s === "h") return "hearts";
  if (s === "s") return "spades";
};

export default ({ data, closeModal, logs }) => {
  const [reducedLogs, setReducedLogs] = useState([]);
  const [currentLog, setCurrentLog] = useState(null);
  const [showLog, setShowLog] = useState(false);

  useEffect(() => {
    const reducedLogs = logs.reduce(
      (acc, log) => {
        if (log.type === "divider") {
          acc.push([]); // Start a new sub-array
        } else {
          acc[acc.length - 1].push(log); // Add the log object to the current sub-array
        }
        return acc;
      },
      [[]]
    );

    setReducedLogs(reducedLogs);
  }, [logs]);

  const openLogs = (log) => {
    setCurrentLog(log);
    setShowLog(true);
  };

  const [columnsData, setColumnsData] = useState([]);
  useEffect(() => {
    setColumnsData(oldColumns(reducedLogs, openLogs));
  }, [reducedLogs]);

  const defaultData = data.hands_statistic.map((hand, idx) => {
    return {
      hand: idx + 1,
      pocket_cards: hand.pocket_cards,
      community_cards: hand.community_cards,
      value: null,
      pre: hand.value_quotients[0] ? hand.value_quotients[0].toFixed(2) : null,
      flop: hand.value_quotients[1] ? hand.value_quotients[1].toFixed(2) : null,
      turn: hand.value_quotients[2] ? hand.value_quotients[2].toFixed(2) : null,
      river: hand.value_quotients[3]
        ? hand.value_quotients[3].toFixed(2)
        : null,
      aggression: hand.contested ? null : hand.chips_delta,
      points: hand.points,
    };
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("hand", {
      header: "Hand #",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("pocket_cards", {
      header: "Hole Cards",
      cell: (info) => {
        return (
          <div className="flex gap-2">
            {(info.getValue() ? info.getValue() : []).map((card) => (
              <SmallCard
                theme={"FOUR_COLOR"}
                rank={card[0] == "T" ? 10 : card[0]}
                suit={getSuit(card[1])}
              />
            ))}
          </div>
        );
      },
    }),
    columnHelper.accessor("community_cards", {
      header: "Board",
      cell: (info) => {
        return (
          <div className="flex gap-2">
            {(info.getValue() ? info.getValue() : []).map(
              (card) =>
                card && (
                  <SmallCard
                    rank={card[0] == "T" ? 10 : card[0]}
                    theme={"FOUR_COLOR"}
                    suit={getSuit(card[1])}
                  />
                )
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("value", {
      header: "Value:",
      footer: (info) => {
        const valueCount = data.hands_statistic
          .filter((hand) => hand.contested)
          .reduce((acc, hand) => {
            console.log(hand);
            if (hand.value_quotients[0] !== null) acc += 1;
            if (hand.value_quotients[1] !== null) acc += 1;
            if (hand.value_quotients[2] !== null) acc += 1;
            if (hand.value_quotients[3] !== null) acc += 1;
            return acc;
          }, 0);

        return (
          <>
            <span className="flex gap-1 items-end justify-center mb-1">
              <img src="/coin.png" class="w-4 h-4" />
              <span>Value:</span>
            </span>
            <span
              className="value flex items-start "
              style={{
                color:
                  parseFloat(data.match_results.value_quotient.value) > 0
                    ? "#5DC05E"
                    : "#E06666",
              }}
            >
              <span>
                {parseFloat(data.match_results.value_quotient.value).toFixed(2)}{" "}
              </span>
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                ({valueCount})
              </span>
            </span>
          </>
        );
      },
    }),
    columnHelper.accessor("pre", {
      header: "Pre",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span></span>;
        }

        return value >= 0 ? (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#5DC05E" }}>{value}</span>
          </div>
        ) : (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#E06666" }}>{value}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("flop", {
      header: "Flop",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span></span>;
        }

        return value >= 0 ? (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#5DC05E" }}>{value}</span>
          </div>
        ) : (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#E06666" }}>{value}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("turn", {
      header: "Turn",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span></span>;
        }

        return value >= 0 ? (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#5DC05E" }}>{value}</span>
          </div>
        ) : (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#E06666" }}>{value}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("river", {
      header: "River",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span></span>;
        }

        return value >= 0 ? (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#5DC05E" }}>{value}</span>
          </div>
        ) : (
          <div className="flex items-start justify-center gap-1">
            <img src="/coin.png" class="w-4 h-4" />
            <span style={{ color: "#E06666" }}>{value}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("aggression", {
      header: "Aggression",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span></span>;
        }

        return value >= 0 ? (
          <div className="flex items-start justify-center gap-1">
            <img src="/lighting.png" class="w-3 h-4" />
            <span style={{ color: "#5DC05E" }}>{value}</span>
          </div>
        ) : (
          <div className="flex items-start justify-center gap-1">
            <img src="/lighting.png" class="w-3 h-4" />
            <span style={{ color: "#E06666" }}>{value}</span>
          </div>
        );
      },
      footer: (info) => {
        const aggressionCount = data.hands_statistic.filter(
          (hand) => !hand.contested
        ).length;
        return (
          <>
            <span className="flex gap-1 items-end justify-center mb-1">
              <img src="/lighting.png" class="w-3 h-4" />
              <span>Aggression:</span>
            </span>
            <span
              className="value"
              style={{
                color:
                  data.match_results.aggression.value > 0
                    ? "#5DC05E"
                    : "#E06666",
              }}
            >
              {(data.match_results.aggression.total / aggressionCount).toFixed(
                2
              )}{" "}
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                ({aggressionCount})
              </span>
            </span>
          </>
        );
      },
    }),
    columnHelper.accessor("points", {
      header: "Points",
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <span style={{ color: "#5DC05E" }}>{0}</span>;
        }

        return value >= 0 ? (
          <span style={{ color: "#5DC05E" }}>{value}</span>
        ) : (
          <span style={{ color: "#E06666" }}>{value}</span>
        );
      },
      footer: (info) => {
        const pointsCount = data.hands_statistic.length;
        return (
          <>
            <span className="flex gap-1 items-end justify-center mb-1">
              <img src="/star.png" class="w-4 h-4" />
              <span>Points:</span>
            </span>{" "}
            <span
              style={{
                color:
                  data.match_results.msr.points.value > 0
                    ? "#5DC05E"
                    : "#E06666",
              }}
              className="value"
            >
              {data.match_results.msr.points.value}
              <span
                className="text-gray-300 font-normal"
                style={{
                  fontFamily: "Play",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {" "}
                ({pointsCount})
              </span>
            </span>
          </>
        );
      },
    }),
  ];

  const [tableData, setTableData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        className="p-2"
        style={{
          overflow: "auto",
          height: "400px",
          minWidth: "600px",
        }}
      >
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={(e) => {
                  openLogs(reducedLogs[row.id]);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </StyledTable>
        <div className="h-4" />
      </div>

      {showLog && (
        <LogsAlert logs={currentLog} onClose={() => setShowLog(false)} />
      )}
    </>
  );
};
