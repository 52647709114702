import React from "react";
import { Box } from "components/utils";
import { Modal, Row } from "antd";
import Button from "scenes/shared/Button";
import { Toggle } from "libreact/lib/Toggle";
import { sleep } from "utils/common";
import LogList from "./LogList";
import styled from "styled-components";
import "react-virtualized/styles.css";

const Content = styled.table`
  border: 1px #eee solid;
  border-radius: 2px;
  height: 100%;
`;

export default ({ logs, onClose }) => (
  <Toggle init>
    {({ on, toggle }) => {
      const closeModalAnd = (action) => async () => {
        toggle();
        await sleep(500);
        action();
      };

      return (
        <Modal
          visible={on}
          closable={false}
          footer={null}
          bodyStyle={{
            padding: 10,
            background: "white",
            borderRadius: "2px",
          }}
          width={355}
          height={550}
          onCancel={closeModalAnd(onClose)}
        >
          <Content>
            <LogList items={logs} width={335} height={450} />
          </Content>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "12px",
            }}
          >
            <Button
              type="primary"
              id="got_it"
              width={120}
              height={40}
              w={120}
              h={40}
              onClick={closeModalAnd(onClose)}
            >
              Exit
            </Button>
          </div>
        </Modal>
      );
    }}
  </Toggle>
);
