// We use these imports for our app to work in the IE11
// Please, see http://www.divby0.io/development/react/2018/04/24/react-ie.html
import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/es6/string";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import IE11Page from "IE11Page";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./app.less";

// See https://stackoverflow.com/questions/21825157/internet-explorer-11-detection?answertab=active#tab-top
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

ReactDOM.render(
  isIE11 ? <IE11Page /> : <App />,
  document.getElementById("root")
);
