import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { lifecycle } from 'recompose'
import { message } from 'antd'

export const RedirectWithMessage = lifecycle({
  componentDidMount() {
    if (this.props.message) message.warning(this.props.message)
  }
})(Redirect)


export const withToken = connect(
  ({ currentUser: { token } }) => ({ token })
)
