import Loadable from "react-loadable";
import FullScreenSpinner from "scenes/shared/FullScreenSpinner";
import * as R from "ramda";

export const formatThousands = (v) => {
  return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const requestDesktopNotificationPermission = () => {
  // navigator.serviceWorker.register("sw.js");
  // if (Notification && Notification.permission === "default") {
  //   Notification.requestPermission((permission) => {
  //     if (!("permission" in Notification)) {
  //       Notification.permission = permission;
  //     }
  //   });
  // }
};

export const noop = function() {};
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const asyncLoad = (loader) =>
  Loadable({
    loader: async () => {
      await sleep(100);
      return loader();
    },
    loading: FullScreenSpinner,
  });

export const throttle = (callback, wait, context = this) => {
  let timeout = null;
  let callbackArgs = null;

  const later = () => {
    callback.apply(context, callbackArgs);
    timeout = null;
  };

  return function() {
    if (!timeout) {
      callbackArgs = arguments;
      timeout = setTimeout(later, wait);
    }
  };
};

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);
export const isPresent = R.complement(isNilOrEmpty);
export const flattenAppend = R.pipe(
  R.append,
  R.flatten
);
