import React from "react"
import { withStateHandlers } from 'recompose'
import { List as VirtualList } from 'react-virtualized';

const BottomAlignedVirtualList = withStateHandlers(
  () => ({ stayAtBottom: true }),
  {
    setStayAtBottom: (state) => (value) => ({...state, stayAtBottom: value})
  }
)(
  ({ items, style, setStayAtBottom, stayAtBottom, onScroll, rowHeight, renderItem: RenderItem, showLogs=true, ...props }) => (
    <VirtualList
      style={{willChange: 'auto', outline: 'none', display: (showLogs ? 'block' : 'none'), ...style}}
      onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
        if(stayAtBottom && scrollTop === 0) { return } // ignore first call
        const isOnBottom = scrollHeight - clientHeight === scrollTop
        if (isOnBottom !== stayAtBottom) {
          setStayAtBottom(isOnBottom)
        }
        onScroll({ clientHeight, scrollHeight, scrollTop })
      }}
      rowCount={items.length}
      rowHeight={({ index }) => rowHeight({index, item: items[index]})}
      scrollToIndex={stayAtBottom ? items.length - 1 : -1}
      rowRenderer={ ({ index, ...props }) => <RenderItem {...items[index]} {...props} /> }
      {...props}
    />
  )
)

BottomAlignedVirtualList.defaultProps = {
  onScroll: () => {},
  rowHeight: () => {},
  items: [],
  style: {}
}

export default BottomAlignedVirtualList
