import { combineReducers } from 'redux'
import scenes from 'reducers/scenes'
import currentUser from 'reducers/currentUser'

const root = combineReducers({
  scenes,
  currentUser
})

export default root
