import React, { useState } from "react";
import Spin from "components/Spin";
import { Box } from "components/utils";

import StatisticsTable from "./StatisticsTable";
import HandsLog from "./HandsLog";
import { TableContainer } from "../ui-old";
import { prepareLogs } from "../../../../../reducers/scenes/matches/show";

export const TABS = [
  {
    key: "statistic",
    title: "Overview",
    content: StatisticsTable,
  },
  {
    key: "hand_log",
    title: "Hand log",
    content: HandsLog,
  },
];

function toCamelCase(str) {
  // Convert snake_case to camelCase
  str = str.replace(/(_\w)/g, (m) => m[1].toUpperCase());
  return str.replace(/-/g, "", str);
}

function convertKeysToCamelCase(obj) {
  if (Array.isArray(obj)) {
    // If it's an array, apply the function to each element
    return obj.map(convertKeysToCamelCase);
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, convert its keys and recursively call on its values
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = toCamelCase(key);
      acc[camelKey] = convertKeysToCamelCase(obj[key]);
      return acc;
    }, {});
  }
  // Return the value if it's neither an object nor an array
  return obj;
}

const Content = ({
  postMatchStatistic,
  activeTab,
  closeModal,
  logs,
  fetched,
  currentUser,
}) => {
  const [stats, setStats] = useState(postMatchStatistic);
  const [statsLogs, setStatsLogs] = useState(logs);

  if (!stats) {
    if (postMatchStatistic) {
      console.log("mooie logs", logs);
      setStats(postMatchStatistic);
      setStatsLogs(logs);
      return;
    }

    const matchId = window.location.href.split("/")[4];

    const baseUrl = process.env.REACT_APP_GRAPHQL_URI.replace("graphql", "api");

    if (logs.length === 0) {
      fetch(`${baseUrl}/matches/${matchId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return;
          }

          console.log("cok", currentUser);

          const convertedGames = convertKeysToCamelCase(res.games);
          console.log("ok", res.games);
          console.log("nok", convertedGames);
          const preparedLogs = prepareLogs(
            convertedGames,
            convertedGames[0].players,
            [],
            "",
            currentUser.id
          );

          console.log("lok", preparedLogs);

          // console.log("wwww", test);
          setStatsLogs(preparedLogs.logs);
          setStats(res.statistics);
        });
    }

    return (
      <TableContainer>
        <Box outside top={20} bottom={20} style={{ textAlign: "center" }}>
          <Spin />
        </Box>
      </TableContainer>
    );
  }

  const { content: Component } = activeTab;

  if (!Component) return null;

  return (
    <TableContainer>
      <Component data={stats} logs={statsLogs} closeModal={closeModal} />
    </TableContainer>
  );
};

export default Content;
