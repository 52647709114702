import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import client from "apollo/client";
import { ApolloProvider } from "react-apollo";
import AdminAuthorizedRoute from "scenes/shared/AdminAuthorizedRoute";
import AuthorizedRoute from "scenes/shared/AuthorizedRoute";
import GuestRoute from "scenes/shared/GuestRoute";
import browserHistory from "browserHistory";
import { asyncLoad } from "utils/common";
import { SocketProvider } from "realtime-tools";
import store from "store";
import { Provider } from "react-redux";
import PostMatchStatistic from "./scenes/Games/Show/PostMatchStatistic";

const NotFound = asyncLoad(() =>
  import(/* webpackChunkName: "NotFound" */ "scenes/shared/NotFound")
);
const SignUp = asyncLoad(() =>
  import(/* webpackChunkName: "SignUp" */ "scenes/SignUp/Scene")
);
const SignUpInvited = asyncLoad(() =>
  import(/* webpackChunkName: "SignUpInvited" */ "scenes/SignUp/Invited/Scene")
);
const SignIn = asyncLoad(() =>
  import(/* webpackChunkName: "SignIn" */ "scenes/SignIn/Scene")
);
const ForgotPassword = asyncLoad(() =>
  import(/* webpackChunkName: "ForgotPassword" */ "scenes/ForgotPassword/Scene")
);
const ResetPassword = asyncLoad(() =>
  import(/* webpackChunkName: "ResetPassword" */ "scenes/ResetPassword/Scene")
);
const Dashboard = asyncLoad(() =>
  import(/* webpackChunkName: "Dashboard" */ "scenes/Dashboard/Scene")
);
const Home = asyncLoad(() =>
  import(/* webpackChunkName: "Home" */ "scenes/Home/Scene")
);
const Games = asyncLoad(() =>
  import(/* webpackChunkName: "Games" */ "scenes/Games/Scene")
);
const Profile = asyncLoad(() =>
  import(/* webpackChunkName: "Profile" */ "scenes/Profile/Scene")
);
const Settings = asyncLoad(() =>
  import(/* webpackChunkName: "Settings" */ "scenes/Settings/Scene")
);
const Ranking = asyncLoad(() =>
  import(/* webpackChunkName: "Ranking" */ "scenes/Dashboard/Ranking")
);

const GameHistory = asyncLoad(() =>
  import(/* webpackChunkName: "Ranking" */ "scenes/Dashboard/GameHistory")
);

const Unsubscribe = asyncLoad(() =>
  import(/* webpackChunkName: "Unsubscribe" */ "scenes/Unsubscribe/Scene")
);

// TODO:admin
const AdminPanel = asyncLoad(() =>
  import(/* webpackChunkName: "AdminPanel" */ "scenes/AdminPanel/Scene")
);
const Invitation = asyncLoad(() =>
  import(/* webpackChunkName: "Invitation" */ "scenes/Invitation/Scene")
);

const Lobbies = asyncLoad(() =>
  import(/* webpackChunkName: "Lobbies" */ "scenes/Lobbies/Scene")
);

const CreateLobby = asyncLoad(() =>
  import(/* webpackChunkName: "CreateLobby" */ "scenes/Lobbies/CreateLobby")
);

const ShowLobby = asyncLoad(() =>
  import(/* webpackChunkName: "CreateLobby" */ "scenes/Lobbies/ShowLobby")
);

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <SocketProvider uri={process.env.REACT_APP_WS_URI}>
          <Router history={browserHistory}>
            <Switch>
              <Route path="/unsubscribe/:token" component={Unsubscribe} />

              <GuestRoute
                exact
                path="/"
                component={SignIn}
                redirectTo={(function() {
                  const urlParams = new URLSearchParams(window.location.search);
                  const room = urlParams.get("room");

                  if (room) {
                    return `/dashboard?room=${room}`;
                  }

                  return "/dashboard";
                })()}
              />

              <AdminAuthorizedRoute
                component={AdminPanel}
                path="/admin"
                redirectTo="/sign-in"
              />
              <AdminAuthorizedRoute
                component={Invitation}
                path="/admin/leagues"
                redirectTo="/sign-in"
              />

              <AuthorizedRoute
                component={Lobbies}
                path="/dashboard"
                redirectTo="/sign-in"
              />

              <AuthorizedRoute
                component={CreateLobby}
                path="/lobbies/new"
                redirectTo="/sign-in"
              />

              <AuthorizedRoute
                component={ShowLobby}
                path="/lobbies/:id"
                redirectTo="/sign-in"
              />

              <AuthorizedRoute
                component={Games}
                path="/matches"
                redirectTo="/sign-in"
              />
              <AuthorizedRoute
                component={Profile}
                path="/profile"
                redirectTo="/sign-in"
              />
              <AuthorizedRoute
                component={Settings}
                path="/settings"
                redirectTo="/sign-in"
              />

              <GuestRoute
                path="/sign-in"
                component={SignIn}
                redirectTo="/dashboard"
              />
              <AuthorizedRoute path="/ranking/:id" component={Ranking} />
              <AuthorizedRoute path="/ranking" component={Ranking} />
              <AuthorizedRoute path="/history" component={GameHistory} />

              <GuestRoute
                path="/sign-up/invitation/:token+"
                component={SignUpInvited}
                redirectTo="/dashboard"
              />
              <GuestRoute
                path="/sign-up/:provider?"
                component={SignUp}
                redirectTo="/dashboard"
              />
              <GuestRoute
                path="/forgot-password"
                component={ForgotPassword}
                redirectTo="/dashboard"
              />
              <GuestRoute
                path="/reset-password/:token"
                component={ResetPassword}
                redirectTo="/dashboard"
              />

              {/* <GuestRoute path='/post-match-stats' component={PostMatchStatistic} /> */}
              <Route component={NotFound} />
            </Switch>
          </Router>
        </SocketProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
