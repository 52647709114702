import React from 'react'
import { Route } from 'react-router-dom'
import { RedirectWithMessage, withToken } from 'utils/routes'

const GuestRoute = ({
  component: Component,
  token,
  redirectTo,
  location,
  render,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return !token
        ? <Component {...props} />
        : <RedirectWithMessage
            to={redirectTo}
          />
    }}
  />
)

export default withToken(GuestRoute)
