import React, { useMemo } from "react";
import styled from "styled-components";

import Suit from "./Suits";
import { suitColor } from "../shared";

const Card = styled.div`
  width: 36px;
  height: 50px;
  border: 3px solid #d4d4d4;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px;
  padding-bottom: 2px;
  line-height: 1;

  span.rank {
    font-family: ContraxHV;
    font-size: 20px;
    height: 22px;
    line-height: 1;
    color: #1e2e37;
  }
  span.suit {
    color: #e06666;
    font-family: ContraxHV;
    line-height: 1;
  }
`;

const Rank = styled.div`
  color: ${(props) => props.color};
  font-family: Play;
  font-weight: bold;
  font-size: 21px;
  line-height: 1;
`;

const Front = ({
  width,
  minWidth,
  height,
  theme,
  rank,
  suit,
  scale,
  style,
  hidden,
  highlighted,
  ...props
}) => {
  const value = useMemo(() => {
    switch (rank) {
      case "ace":
        return "A";
      case "king":
        return "K";
      case "queen":
        return "Q";
      case "jack":
        return "J";
      default:
        return rank;
    }
  }, [rank]);

  const color = suitColor(suit, theme).color;

  const icon = useMemo(() => {
    switch (suit) {
      case "hearts":
        return (
          <svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6859 0.669922C9.94833 0.669922 9.27187 0.90355 8.67585 1.3647C8.10453 1.80674 7.72408 2.36904 7.50001 2.77895C7.27594 2.36904 6.8955 1.80674 6.32417 1.3647C5.72815 0.90355 5.05169 0.669922 4.31417 0.669922C2.25585 0.669922 0.703552 2.35364 0.703552 4.58612C0.703552 6.99833 2.64001 8.64806 5.57178 11.1468C6.06957 11.5716 6.63373 12.0521 7.22045 12.5651C7.29787 12.6327 7.39719 12.67 7.50001 12.67C7.60283 12.67 7.70216 12.6327 7.77957 12.5651C8.36629 12.0521 8.93045 11.5711 9.42851 11.1466C12.36 8.64859 14.2965 6.99833 14.2965 4.58612C14.2965 2.35364 12.7442 0.669922 10.6859 0.669922Z"
              fill={color}
            />
          </svg>
        );
      case "diamonds":
        return (
          <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.08579 2.08413C9.86684 1.30309 11.1332 1.30309 11.9142 2.08414L17.0858 7.25571C17.8668 8.03676 17.8668 9.30309 17.0858 10.0841L11.9142 15.2557C11.1332 16.0368 9.86683 16.0368 9.08579 15.2557L3.91421 10.0841C3.13316 9.30309 3.13317 8.03676 3.91421 7.25571L9.08579 2.08413Z"
              fill={color}
            />
          </svg>
        );
      case "spades":
        return (
          <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.7699 13.9951L12.7546 15.9445C12.9227 16.277 12.681 16.6699 12.3084 16.6699H8.58217C8.20957 16.6699 7.96788 16.277 8.13588 15.9445L9.04878 14.1374C8.3637 14.6465 7.59053 14.9043 6.74997 14.9043C4.32718 14.9043 2.5 12.9224 2.5 10.2947C2.5 7.45614 4.778 5.51459 8.22719 2.57483L8.23028 2.5722C8.26702 2.54084 8.30406 2.50924 8.34141 2.47738C8.89959 2.00112 9.52357 1.46872 10.1709 0.902832C10.262 0.823183 10.3789 0.779286 10.5 0.779286C10.621 0.779286 10.7379 0.823183 10.829 0.902832C11.5196 1.50658 12.1837 2.07282 12.7699 2.57251C16.2205 5.5128 18.4999 7.45529 18.4999 10.2947C18.4999 12.9224 16.6727 14.9043 14.2499 14.9043C13.3818 14.9043 12.5856 14.6293 11.884 14.0865C11.8452 14.0565 11.8072 14.026 11.7699 13.9951Z"
              fill={color}
            />
          </svg>
        );
      case "clubs":
        return (
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7468 5.4477C11.8909 5.10924 11.0916 5.10114 10.3647 5.2512C10.9569 4.65437 11.2979 3.87111 11.2445 3.0188C11.1429 1.39455 9.55585 0.0645435 7.64711 0.00239092C5.50904 -0.0671171 3.75001 1.3879 3.75001 3.19292C3.75001 3.97767 4.08377 4.69511 4.63529 5.25118C3.90836 5.10111 3.10905 5.10921 2.25319 5.44767C0.935788 5.96887 0.0190694 7.07192 0.000314946 8.3086C-0.0265 10.0915 1.66243 11.5438 3.75001 11.5438C4.75877 11.5438 5.67231 11.2032 6.34615 10.6515V11.5922C6.34615 12.0434 6.20796 12.4868 5.94489 12.8785L5.43894 13.6326C5.32904 13.7964 5.46806 14 5.68933 14H9.31067C9.53222 14 9.67095 13.7964 9.56106 13.6326L9.0551 12.8785C8.79232 12.4868 8.65384 12.0434 8.65384 11.5922V10.6515C9.32797 11.2032 10.2415 11.5438 11.25 11.5438C13.3376 11.5438 15.0268 10.0915 14.9997 8.3086C14.9809 7.07192 14.0642 5.96887 12.7468 5.4477Z"
              fill={color}
            />
          </svg>
        );
      default:
        return suit;
    }
  });

  const styles = {
    ...style,
    transformOrigin: "center top",
    visibility: hidden ? "hidden" : "visible",
  };

  if (highlighted) {
    styles["animation"] = "winnerA 1.5s forwards";
  }

  return (
    <Card style={styles}>
      <span class="rank">{value}</span>
      <span class="suit" style={{ color: color }}>
        {icon}
      </span>
    </Card>
  );
};

Front.defaultProps = {
  scale: 2.165,
  width: 110,
  height: 145,
};

export default Front;
