import React from "react";
import LargeLogo from "../../assets/images/welcome.svg";
import { Row } from "antd";
import styled from "styled-components";

const Wrapper = styled.div``;

const Greeting = () => (
  <Wrapper>
    <Row type="flex" justify="center">
      <LargeLogo style={{ maxWidth: "324px", maxHeight: "81px" }} />
    </Row>
  </Wrapper>
);

export default Greeting;
