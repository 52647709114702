import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import rootReducer from 'reducers'

export default function configureStore(initialState) {
  const loggerMiddleware = createLogger({ duration: true })

  const finalCreateStore = compose(
    applyMiddleware(
      loggerMiddleware
    )
  )(createStore)

  return finalCreateStore(rootReducer, initialState)
}
