import React from "react";
import { Route } from "react-router-dom";
import { RedirectWithMessage, withToken } from "utils/routes";

function getCookie(name) {
  let cookies = document.cookie;
  let parts = cookies.split("; " + name + "=");
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

const AuthorizedRoute = ({
  component: Component,
  token,
  redirectTo,
  location,
  render,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!token || token === "") {
        token = getCookie("token");
        localStorage.setItem("token", token);

        if (token) {
          window.location.reload();
        }
      }

      return token ? (
        <Component {...props} />
      ) : (
        <RedirectWithMessage
          message={`You need to sign in to access ${location.pathname}`}
          to={redirectTo}
        />
      );
    }}
  />
);

export default withToken(AuthorizedRoute);
