import types from 'constants/actionTypes'

const initialState = {
  token: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY)
}

export default function (state = initialState, action = {}) {
  const { type } = action

  switch (type) {
    case types.SET_AUTH_TOKEN: {
      const { token } = action.payload

      return {
        ...state,
        token
      }
    }
    default:
      return state
  }
}
