import React from 'react'
import styled, { keyframes } from 'styled-components'

const loading1 = keyframes`
  0% {
    width: 100%;
  }
  16% {
    width: 25%;
  }
  33% {
    width: 50%;
  }
  50% {
    width: 25%;

  }
  66% {
    width: 100%;
  }
  83% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
`
const loading2 = keyframes`
  0% {
    width: 75%;
  }
  16% {
    width: 50%;
  }
  33% {
    width: 25%;
  }
  50% {
    width: 50%;

  }
  66% {
    width: 0%;
  }
  83% {
    width: 50%;
  }
  100% {
    width: 75%;
  }
`

const loading3 = keyframes`
  0% {
    width: 25%;
  }
  16% {
    width: 100%;
  }
  33% {
    width:  %;
  }
  50% {
    width: 25%;
  }
  66% {
    width: 75%;
  }
  83% {
    width: 50%;
  }
  100% {
    width: 25%;
  }
`

const Container = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

const Bar = styled.div`
  width: 100%;
  height: 20%;
  border-radius: 2px;
  background-color: #df9fc5;

  &:nth-child(1) {
    animation: ${loading1} 1.8s linear infinite;
  }
  &:nth-child(2) {
    animation: ${loading2} 1.8s linear infinite;
  }
  &:nth-child(3) {
    animation: ${loading3} 1.8s linear infinite;
  }
`
const Wrapper = styled.div`
  display: inline-block;
`

const Spin = ({size}) => (
  <Wrapper>
    <Container size={size}>
      <Bar/>
      <Bar/>
      <Bar/>
    </Container>
  </Wrapper>
)

Spin.defaultProps = {
  size: 15 // divisible by 5 (3 colored bars and 2 white spaces between)
}

export default Spin
