import React from "react";
import { Route } from "react-router-dom";
import { RedirectWithMessage, withToken } from "utils/routes";

import { Query } from "react-apollo";
import Loading from "components/Loading";
import Spin from "components/Spin";
import gql from "graphql-tag";

const isAdmin = (user) => user.role === "ADMIN";

// TODO: We need to store user's data into redux store on success authentication
// to prevent multiple queries.
const currentUserQ = gql`
  query currentUser {
    currentUser {
      id
      profileImage
      email
      beats
      username
      role
    }
  }
`;

const AdminAuthorizedRoute = ({
  component: Component,
  token,
  redirectTo,
  location,
  render,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Query query={currentUserQ}>
        {(queryProps) => (
          <Loading
            {...queryProps}
            renderSpinner={() => <Spin />}
            renderError={(error) => <div />}
            render={(data) => {
              return token && isAdmin(data.currentUser) ? (
                <Component {...props} />
              ) : (
                <RedirectWithMessage
                  message={`You need extra permissions to access ${location.pathname}`}
                  to={redirectTo}
                />
              );
            }}
          />
        )}
      </Query>
    )}
  />
);

export default withToken(AdminAuthorizedRoute);
