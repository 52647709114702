import React, { useMemo } from "react";
import styled from "styled-components";

import Suit from "./Suits";
import { suitColor } from "../shared";
import Community from "./Community";
const Card = styled.div`
  width: 35px;
  height: 50px;
  border: 3x solid #d4d4d4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px 0px;

  span {
    color: #1e2e37;
    font-family: ContraxSb;
  }
`;

// const Rank = styled.div`
//   color: ${(props) => props.color};
//   font-family: Play;
//   font-weight: bold;
//   font-size: 24px;
//   line-height: 1;
// `;

const Front = ({
  width,
  minWidth,
  height,
  theme,
  rank,
  suit,
  scale,
  ...props
}) => {
  return <Community rank={rank} suit={suit} theme={theme} {...props} />;
};

Front.defaultProps = {
  scale: 2.165,
  width: 110,
  height: 145,
};

export default Front;
