import React from "react";
import styled from "styled-components";
import * as colors from "styles/colors";
import * as breakpoints from "constants/sizes";

const Text = styled.span`
  font-family: "Houschka Pro";

  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};

  color: ${(props) => {
    if (props.white) return colors.white;
    if (props.pink) return colors.pink;
    if (props.fuscousPink) return colors.fuscousPink;
    if (props.lightPink) return colors.lightPink;
    if (props.darkPink) return colors.darkPink;
    if (props.gunmetal) return colors.gunmetal;
  }};

  cursor: ${(props) => props.pointer && "pointer"};

  :hover {
    text-decoration: ${(props) => props.hover && "underline"};
  }

  text-decoration: ${(props) => props.underline && "underline"};
  color: ${(props) => props.textColor && props.textColor + " !important"};

  font-family: ${(props) => {
    if (props.agencyFont) return "Agency FB";
    if (props.contraxHVFont) return "ContraxHV";
    if (props.playFont) return "Play";
  }};

  line-height: ${(props) => {
    if (props.compactLineHeight) return "0.7";
    if (props.middleLineHeight) return "1";
  }};

  letter-spacing: ${(props) => {
    if (props.wideSpacing) return "6.6px";
    if (props.semiWideSpacing) return "3.6px";
  }};

  font-weight: ${(props) => {
    if (props.bold) return "bold";
  }};

  text-align: ${(props) => props.textAlign};

  text-transform: ${(props) => props.uppercase && "uppercase"};

  padding: ${(props) => {
      if (props.padding) return props.padding;
    }}
    @media all and (min-width: ${breakpoints.highDpiDesktop}) {
    font-size: ${(props) => props.fontSizeHDD};
  }

  @media all and (min-width: ${breakpoints.desktop}) {
    font-size: ${(props) => props.fontSizeDesktop};
  }

  @media all and (min-width: ${breakpoints.ipadProTablet}) {
    font-size: ${(props) => props.fontSizeIpadProTablet};
  }

  @media all and (min-width: ${breakpoints.tablet}) {
    font-size: ${(props) => props.fontSizeTablet};
  }
`;

export default Text;
