import React from 'react'
import * as PropTypes from 'prop-types'
import { Socket } from 'phoenix'
import { withToken } from 'utils/routes'
import { noop } from 'utils/common'

class SocketProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      socket: null
    }
  }

  ensureSocketConnection(token) {
    if (token !== null && this.state.socket === null) {
      this.setState({
        socket: new Socket(this.props.uri, { params: { token: token } })
      }, () => {
        const { socket } = this.state
        const { onOpen, onError, onClose } = this.props

        
        socket.onOpen(onOpen)
        socket.onError(onError)
        socket.onClose(onClose)
        this.state.socket.connect()
        socket.channel("users:all").join();
      })
    }

    // Disconnect from socket when user logouts
    if (token === null && this.state.socket !== null) {
      this.state.socket.disconnect()
      this.setState({ socket: null })
    }
  }

  componentDidMount() {
    const { token } = this.props
    this.ensureSocketConnection(token)
  }

  componentWillReceiveProps(nextProps) {
    const { token } = nextProps
    this.ensureSocketConnection(token)
  }

  componentWillUnmount() {
    this.props.socket && this.props.socket.disconnect()
  }

  getChildContext() {
    return {
      socket: this.state.socket
    }
  }

  render() {
    return React.Children.only(this.props.children)
  }
}

SocketProvider.childContextTypes = {
  socket: PropTypes.object
}

SocketProvider.defaultProps = {
  onOpen: noop,
  onError: noop,
  onClose: noop
}

export default withToken(SocketProvider)
