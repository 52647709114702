import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import WelcomeLogo from "../../assets/images/welcome.svg";
import Greeting from "./Greeting";

const LOGO_LINE_SIZE = 0;

const Layout = styled.div`
  height: 100%;
  background-position: center;
  display: flow-root;
  padding: 16px;
`;

const LoginHeader = styled.div`
  display: flex;
  max-width: 700px;
  flex-direction: column;
  margin: 40px auto 20px;
  justify-content: center;
  align-items: center;
`;

const RecoveryHeader = styled.div`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const BackButton = styled(Link)`
  position: relative;
  font-size: 32px;
  color: #fff;
  font-family: "ContraxHV";
  padding-left: 66px;
  &::before {
    position: absolute;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 30px 25px 0;
    border-color: transparent #ffffff transparent transparent;
  }
`;

Layout.Content = styled.div`
  padding-top: ${LOGO_LINE_SIZE}px;
`;

Layout.Header = ({ type, children }) => {
  switch (type) {
    case "login_page": {
      return (
        <LoginHeader>
          <Greeting />
          {children}
        </LoginHeader>
      );
    }
    case "recovery_page": {
      return (
        <React.Fragment>
          <RecoveryHeader>
            <WelcomeLogo style={{ maxWidth: "324px", maxHeight: "81px" }} />
          </RecoveryHeader>
          {children}
        </React.Fragment>
      );
    }
    default: {
      return null;
    }
  }
};

export { LOGO_LINE_SIZE };
export default Layout;
